.about-content img {
    max-width: 100%;
    height: auto;
}

.card.mb-4 {
    padding: 15px;
}

.table-responsive {
    overflow-x: auto;
}

.table td,
.table th {
    white-space: nowrap;
}