.post-content p {
    line-height: 0.5em;
}

.table th,
.table td {
    white-space: nowrap;
    /* 텍스트 줄바꿈을 방지 */
    text-align: center;
    /* 기본적으로 중앙 정렬 */
}

.table th:nth-child(2) {
    text-align: center;
    /* 제목 헤더 중앙 정렬 */
}

.table td:nth-child(2) {
    text-align: left;
    /* 게시글 제목은 왼쪽 정렬 */
}

.table th:nth-child(1),
.table td:nth-child(1) {
    width: 5%;
    /* 번호 열의 고정 너비 */
}

.table th:nth-child(3),
.table td:nth-child(3) {
    width: 15%;
    /* 작성자 열의 고정 너비 */
}

.table th:nth-child(4),
.table td:nth-child(4) {
    width: 15%;
    /* 날짜 열의 고정 너비 */
}

.table th:nth-child(5),
.table td:nth-child(5) {
    width: 10%;
    /* 조회수 열의 고정 너비 */
}

/* 모바일에서의 반응형 디자인 */
@media (max-width: 768px) {

    .table th:nth-child(1),
    .table td:nth-child(1),
    .table th:nth-child(3),
    .table td:nth-child(3),
    .table th:nth-child(4),
    .table td:nth-child(4),
    .table th:nth-child(5),
    .table td:nth-child(5) {
        width: auto;
        /* 모바일에서는 자동 너비 조정 */
    }

    .table td:nth-child(2) {
        white-space: normal;
        /* 제목이 긴 경우 줄바꿈 허용 */
    }
}