/* ErrorPage.css */
/* body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
} */

.error-container {
    text-align: center;
}

.error-image {
    max-width: 100%;
    height: auto;
}

.error-title {
    font-size: 3rem;
    color: #007bff;
}

.error-message {
    font-size: 1.2rem;
    color: #6c757d;
}

.home-button {
    margin-top: 20px;
}